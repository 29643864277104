import { PublicClientApplication } from "@azure/msal-browser"
import { User } from "../machines/authMachine.machine"

// TODO: I don't think we are using this function because we may not be
// using login/logout page anymore. Perhaps we can remove this file?
export const getCurrentUser = async (): Promise<User | null> => {
  let user = null,
    msalClient: PublicClientApplication | null = null

  if (typeof window !== "undefined") {
    const { msalClient: machineMsalClient } = await import(
      "../machines/authMachine.machine"
    )

    msalClient = machineMsalClient
  }

  if (!msalClient) {
    return null
  }

  try {
    // grab the user directly from the session which requires third party cookie access
    const accounts = await msalClient.getAllAccounts()
    const currentUser = accounts?.[0]

    if (currentUser) {
      user = {
        email: currentUser?.idTokenClaims?.emails?.[0] || "",
        name: currentUser.name,
        username: currentUser.username,
        sub: currentUser.idTokenClaims?.sub || "",
        exp: currentUser.idTokenClaims?.exp || null
      }
    }
  } catch (e) {
    console.log(`Could not fetch user: `, (e as Error).message)
    user = null
  }

  return user
}
